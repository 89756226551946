/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormEvent } from 'react'
import { useModalContext } from '../../contexts/contexts/ModalContext'
import CharFieldForm from '../fields/Charfield'
import CheckField from '../fields/CheckField'
import DateTimeField from '../fields/DateTimeField'
import TextAreaField from '../fields/TextAreaField'


const GetSampleForm = () => {
    const [getModalContext, setModalContext] = useModalContext()

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setModalContext({ type: "SHOW", payload: false })
    }


    return (
        <form onSubmit={onSubmit} >

            <div className='row mb-10'>
                <div className="col">
                    <CharFieldForm name='processo_id' title='Identificador da Amostra' type="text" required={false} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <DateTimeField name='created_at' title='Registrada em' required={false} defaultValue={getModalContext.data} />
                </div>
            </div>
            <div className="row mb-10">
                <div className="col">
                    <CharFieldForm name='maquina_id' title='Maquina Utilizada' type="email" required={false} editable={false} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <CharFieldForm name='produto.name' title='Produto' type="email" required={false} editable={false} defaultValue={getModalContext.data} />
                </div>
            </div>
            <div className="row mb-10">
                <div className="col">
                    <CheckField name="descartada" title="Descartada?" editable={false} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <CheckField name="pendencia" title="Pendente?" editable={false} defaultValue={getModalContext.data} />
                </div>
            </div>

            <div className="row mb-10">
                <TextAreaField name='analises' title='Analises' type="json" required={false} editable={false} defaultValue={getModalContext.data} />
            </div>
            <div className="row my-3 justify-content-end">
                <div className="col-2">
                    <input type="submit" className='btn btn-lg btn-primary w-100' value={"Close"} />
                </div>
            </div>
        </form>
    )
}
export { GetSampleForm }

