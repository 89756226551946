/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormEvent, useEffect } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { ApiClass } from '../../classComponents'
import { useModalContext } from '../../contexts/contexts/ModalContext'
import { usePagination } from '../../contexts/contexts/PaginationContext'
import { useUser } from '../../contexts/contexts/UserContext'
import CharFieldForm from '../fields/Charfield'
import CheckField from '../fields/CheckField'


const EditUserForm = () => {
    const [getModalContext, setModalContext] = useModalContext()
    const [user] = useUser()
    const api = new ApiClass<any>(user.credential)
    const [pagination] = usePagination()
    const { refetch } = useQuery(`users-list-${JSON.stringify(pagination)}`, async (): Promise<userType[] | userType | IPagination<userType>> => api.get("users", { ...pagination }))

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (getModalContext.type === 'POST') {
            api.create("users", {
                first_name: e.currentTarget.first_name.value,
                last_name: e.currentTarget.last_name.value,
                email: e.currentTarget.email.value,
                manager: e.currentTarget.manager.checked
            }).then((response) => {
                toast.success("Usuário criado com sucesso!")
            }).catch((error) => {
                toast.error(error.message)
            })
        } else {
            api.partial_update("users", getModalContext.data.id, {
                first_name: e.currentTarget.first_name.value,
                last_name: e.currentTarget.last_name.value,
                manager: e.currentTarget.manager.checked
            }).then((response) => {
                toast.success("Usuário atualizado com sucesso!")
            }).catch((error) => {
                toast.error(error.message)
            })
        }
        setTimeout(refetch, 1000)
        setModalContext({ type: "SHOW", payload: false })
    }


    return (
        <form onSubmit={onSubmit}>
            <div className='row mb-10'>
                <div className="col">
                    <CharFieldForm name='first_name' title='Nome' type="text" required={true} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <CharFieldForm name='last_name' title='Sobrenome' type="text" required={false} defaultValue={getModalContext.data} />
                </div>
            </div>
            <div className="row mb-10">
                <div className="col">
                    <CharFieldForm name='email' title='Email' type="email" required={true} editable={false} defaultValue={getModalContext.data} />
                </div>
            </div>
            <div className="row mb-10">
                <label className='mb-5 required fw-semibold' htmlFor="">Permissão: </label>
                <CheckField name="manager" title="Gestor" editable={true} defaultValue={getModalContext.data ? { manager: getModalContext.data.groups.length > 0 ? getModalContext.data.groups[0].name == "Gestor" : false } : { manager: false }} />
            </div>
            <div className="row my-3 justify-content-end">
                <div className="col-2">
                    <input type="submit" className='btn btn-lg btn-primary w-100' value={getModalContext.data ? "Salvar" : "Criar"} />
                </div>
            </div>
        </form>
    )
}
export { EditUserForm }

