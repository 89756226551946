import { ResponsiveBar } from '@nivo/bar'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


import moment from 'moment'
import { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { ApiClass } from '../../classComponents'
import { useUser } from '../../contexts/contexts/UserContext'
import { themeChartNivoContext } from '../../contexts/observer/ThemeObserver'
import ErrorLoading from '../errorLoading'
import Loading from '../loading'
import TooltipCustom from './tooltip_custom'
import { useFilterContext } from '../../contexts/contexts/FilterContext'
import { getLastDayOfMonth } from './utils'


type ResponseApi = {
    date: string,
    value: number,
    count: number
}

export default function Chart2() {
    const [user] = useUser()
    const [filter] = useFilterContext()
    const themeChart = useContext(themeChartNivoContext)
    const api = new ApiClass<any>(user.credential)
    const created_at_before = filter.created_at_before
    const created_at_after = moment(created_at_before, "yyyy-M-D").subtract(29, 'days').isSameOrAfter(moment(filter.created_at_after, "yyyy-M-D")) ? moment(created_at_before, "yyyy-M-D").subtract(29, 'days').format("yyyy-M-D") : filter.created_at_after

    const { data, isLoading, error } = useQuery(`getSadiosByDayGraficos-${JSON.stringify({ ...filter })}`,
        () => api.get(["graph", "get_sadios_by_day"], {
            ...filter,
            created_at_after: created_at_after,
            created_at_before: created_at_before
        }))

    const formatData = (data: any): { "dia/mes": string, "sadios": number, "avarias": number }[] => {
        const before_now = moment(created_at_before, "yyyy-M-D") || moment()
        const after = moment(created_at_after, "yyyy-M-D") || moment()
        let data_formated: any[] = []
        if(data === undefined || data === null) return data_formated

        for (var m = after; m.isSameOrBefore(before_now); m.add(1, 'days')) {

            const data_filtered: [] = data.filter((x: ResponseApi) => moment(x.date, "YYYY-MM-DD").isSame(m))
            if (data_filtered.length === 0 || null) {
                data_formated.push(
                    {
                        "dia/mes": m.format("DD / MM"),
                        "sadios": 0,
                        "avarias": 0
                    }
                )
            } else {
                data_formated.push(
                    {
                        "dia/mes": m.format("DD / MM"),
                        "sadios": data.filter((x: ResponseApi) => moment(x.date, "YYYY-MM-DD").isSame(m))[0].value.toFixed(2),
                        "avarias": (100 - data.filter((x: ResponseApi) => moment(x.date, "YYYY-MM-DD").isSame(m))[0].value).toFixed(2)
                    }
                )
            }
        }
        return data_formated
    }

    const formatedDataMemo = useMemo(() => formatData(data), [filter, data])

    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error="Erro ao carregar o Chart2" objectError={error} />


    return (
        <ResponsiveBar
            data={formatedDataMemo}
            keys={[
                'avarias',
            ]}
            indexBy="dia/mes"
            margin={{ top: 15, right: 20, bottom: 50, left: 50 }}
            padding={0.3}
            maxValue={Math.max(...formatedDataMemo.map(elem => elem.avarias))}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={d => "#E74C3C"}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: {
                        id: 'fries'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'sandwich'
                    },
                    id: 'lines'
                }
            ]}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            tooltip={data => {
                return <TooltipCustom {...data} />
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 45,
                legend: 'dia/mês',
                legendPosition: 'middle',
                legendOffset: 45
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'avarias',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            theme={themeChart}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            // legends={[
            //     {
            //         dataFrom: 'keys',
            //         anchor: 'bottom-right',
            //         direction: 'column',
            //         justify: false,
            //         translateX: 120,
            //         translateY: 0,
            //         itemsSpacing: 2,
            //         itemTextColor: "#fff",
            //         itemWidth: 100,
            //         itemHeight: 20,
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 0.85,
            //         symbolSize: 20,
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemOpacity: 1
            //                 }
            //             }
            //         ]
            //     }
            // ]}
            role="application"
            ariaLabel="Mês por dias Sadios"
        />

    )
}


