import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "bootstrap/dist/js/bootstrap.min.js"
import './index.css';
import { RouterProvider } from 'react-router-dom';
import Router from './routers';

// metronic Imports
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { LayoutProvider, LayoutSplashScreen } from './_metronic/layout/core';
import { ThemeModeProvider } from './_metronic/partials';
import { MasterInit } from './_metronic/layout/MasterInit';
import { Provider } from 'jotai';
import store from './jotai';

// import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.min.css'
import 'rsuite/styles/index.less';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    
      <Suspense fallback={<LayoutSplashScreen />}>
      <Provider store={store}>
        <LayoutProvider>
          <ThemeModeProvider>
            <RouterProvider router={Router} />
            <MasterInit />
          </ThemeModeProvider>
        </LayoutProvider>
        </Provider>
      </Suspense>
  
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
