import React from 'react'

type Props = {
    name: string;
    title?: string;
    defaultValue?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    errorMessage?: string;
    hasError?: boolean;
    type?: "json" | "text";
    required?: boolean;
    editable?: boolean;
}

export default function TextAreaField({ name, title, defaultValue = {}, onChange, editable, errorMessage, placeholder, hasError, type, required }: Props) {
    const name_array = name.split(".")
    const [value] = React.useState<string>(name_array.length === 2 ? (defaultValue[name_array[0]] && defaultValue[name_array[0]][name_array[1]] ? defaultValue[name_array[0]][name_array[1]] : "") : (defaultValue[name] ? defaultValue[name] : ""));

    return (
        <div className='row'>
            <div className="col">
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className={required ? 'required' : ''}>{title ? title : name}</span>
                    <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title={title}
                    ></i>
                </label>
                <textarea className='form-control form-control-lg form-control-solid h-auto ' rows={10} name="analises" disabled={!editable && defaultValue[name]}>
                    {type === "json" ? JSON.stringify(value, null, 1).replaceAll('"', '').replaceAll('{', '').replaceAll('}', '') : value.toString()}
                </textarea>
                {/* <input
                    type={type ? type : 'text'}
                    className='form-control form-control-lg form-control-solid'
                    name={name}
                    disabled={(name_array.length === 2 ? defaultValue[name_array[0]][name_array[1]] : defaultValue[name]) && !editable}
                    required={required}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange ? onChange : (e) => { setValue(e.target.value) }}
                /> */}
                {!defaultValue && hasError && (
                    <div className='fv-plugins-message-container'>
                        <div data-field={name} data-validator='notEmpty' className='fv-help-block'>
                            {errorMessage}
                        </div>
                    </div>
                )}
            </div>


        </div>
    )
}
