/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../_metronic/helpers'
import { createContext } from 'react'
type Props = {
    show: boolean
    handleClose: () => void,
    title?: string,
    // Form or body of the modal
    children?: React.ReactNode,
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void,
}

const modalsRoot = document.getElementById('root-modals') || document.body

const BaseModal = ({ show, handleClose, title, onSubmit, children }: Props) => {
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='modal-header'>
                <h2>{title}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
                {/*begin::Content */}
                <div className='flex py-lg-5 px-lg-15'>
                    {/*begin::Form */}
                    {/* <form id='kt_modal_create_app_form' onSubmit={onSubmit}> */}
                        {/*begin::Form Group */}
                        {children}
                        {/*end::Form Group */}
                        {/*end::Actions */}
                    {/* </form> */}
                    {/*end::Form */}
                </div>
                {/*end::Content */}

            </div >
        </Modal >,
        modalsRoot
    )
}





export { BaseModal }
