import { createContext, useContext, useReducer } from "react";
import PaginationReducer from "../reducers/PaginationReducer";

const defaultValues = { offset: 0, limit: 10 }

export const PaginationContext = createContext<ContextType<PaginationContextType>>([defaultValues, () => { }])

export function PaginationContextWrapper({ children }: any) {
    const [pagination, dispatch] = useReducer(PaginationReducer, defaultValues)
    return (
        <PaginationContext.Provider value={[pagination, dispatch]}>
            {children}
        </PaginationContext.Provider>
    )
}

export function usePagination() {
    return useContext(PaginationContext)
}