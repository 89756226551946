import { ModalPropsType } from "../contexts/ModalContext"

export default function ModalReducer(value: ModalPropsType, action: any) {
    switch (action.type) {
        case "SET":
            return {...value, ...action.payload}
        case "SET_TOOLBAR":
            return { ...value, toolbar: action.payload }
        case "SHOW":
            return { ...value, show: action.payload }
        case "RESET":
            return {}
        default:
            return value
    }
}