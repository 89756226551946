import React from 'react';

type CardProps = {
    body?: React.JSX.Element;
    title?: string;
    description?: string;
    footer?: React.JSX.Element;
    height?: string;
}

export default function Card(CardProps: CardProps) {
    return (
        <div className="card card-custom">
            <div className="card-header">
                <h3 className="card-title w-100 lh-sm">{CardProps?.title}</h3>
                {/* <div className="card-toolbar">
                    <button type="button" className="btn btn-sm btn-light">
                        Action
                    </button>
                </div> */}
            </div>
            {
                CardProps?.body ? <div className={`card-body card-scroll  ${CardProps.height ? CardProps.height : 'h-150px'} p-0`}>
                    <small>{CardProps?.description}</small>
                    {CardProps?.body}
                </div> : null
            }
            {
                CardProps?.footer ?
                    <div className="card-footer">
                        Footer
                    </div> : null
            }
        </div>
    )
}
