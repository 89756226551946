import moment from "moment";

export function DateCheckParam(date: string | undefined, format: string = "yyyy-M-D"): moment.Moment {
    let date_param = null;
    if (date === undefined) date_param = moment()
    else date_param = moment(date, format)
    return date_param
}
export function daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
}
export function getLastDayOfMonth(date: string | undefined): string {
    const dateParam = DateCheckParam(date)
    const last_day = daysInMonth(dateParam.month(), dateParam.year()) - 1;
    return moment([dateParam.year(), dateParam.month(), last_day]).format("yyyy-M-D")
}
export function firstDayOfYear(date: string | undefined, format: string = "yyyy-M-D"): string {
    const dateParam = DateCheckParam(date)
    return moment([dateParam.year(), 0, 1]).format(format)
}

export function lastDayOfYear(date: string | undefined, format: string = "yyyy-M-D"): string {
    const dateParam = DateCheckParam(date)
    
    return moment([dateParam.year(), 11, 29]).format(format)
}