import moment from "moment"
import { toast } from "react-toastify"

export default function FilterReducer(filter: IFilterContext, action: any) {
    switch (action.type) {
        case "SET_YEAR":
            return { ...filter, year: action.payload, created_at__gt: +action.payload + "-01-02", created_at__lt: +action.payload + "-12-31" }
        case "SET_TYPE":
            return { ...filter, produto: action.payload || null }
        case "SET_CREATED_AT_BEFORE":
            if (moment(action.payload, "yyyy-M-D").isBefore(filter.created_at_after)) {
                toast.error("Data Antes não pode ser maior que a data Depois!")
                return { ...filter }
            }
            return { ...filter, created_at_before: action.payload }
        case "SET_CREATED_AT_AFTER":
            if (moment(action.payload, "yyyy-M-D").isAfter(filter.created_at_before)) {
                toast.error("Data Depois não pode ser menor que a data Antes!")
                return { ...filter }
            }
            
            return { ...filter, created_at_after: action.payload }
        case "SET_REGIONAL":
            return { ...filter, maquina_id__regional: action.payload || null, regional: action.payload || null }
        default:
            return filter
    }
}