import React from 'react'
import { useNavigate } from 'react-router-dom';


type CardProps = {
    image_url?: string;
    title: string;
    description: string;
    redirect: string;
    active?: boolean;
}
export default function Card({ image_url, title, description, redirect, active }: CardProps) {

    const navigate = useNavigate()
    return (
        <div className="col-lg-3 col-md-3">
            <div className="card card-custom card-stretch-50 shadow mb-5">
                <div className="card-header p-0">
                    {image_url ?
                        <h3 className="card-title m-0"><img src={image_url} className="card-img-top" alt="classificador" height={"180px"} style={active ? {filter: 'grayscale(0%)'} : {filter: 'grayscale(100%)'}} /></h3>
                        : null}
                </div>
                <div className="card-body">
                    {title ? <h6>{title}</h6> : null}
                    {description ? <p>{description}</p> : null}
                </div>
                <div className="card-footer">
                    {active ? <button className='btn btn-success' onClick={() => navigate(redirect)}>Acessar</button> : <button className='btn btn-secondary' onClick={() => navigate('')}>Conhecer</button> }
                    
                </div>
            </div>
        </div>
    )
}
