/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { KTIcon, useDebounce } from '../../../../../_metronic/helpers'
import { usePagination } from '../../../../../contexts/contexts/PaginationContext'


const UsersListSearchComponent = () => {
  const [pagination, dispatch] = usePagination()
  const [searchTerm, setSearchTerm] = useState<string>(pagination.search || '')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Capture url query param to search

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("search") !== null) setSearchTerm(urlParams.get("search") as string)
  }, [])


  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        // updateState({search: debouncedSearchTerm, ...initialQueryState})
        dispatch({ type: "SET_SEARCH", payload: debouncedSearchTerm })
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Buscar...'
          value={searchTerm}
          onChange={(e) => { setSearchTerm(e.target.value) }}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export { UsersListSearchComponent }

