// import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import { useUser } from '../../../../../contexts/contexts/UserContext'
import { toAbsoluteUrl } from '../../../../helpers'
import { useLayout } from '../../../core'
import { MenuItem } from './MenuItem'
import DivWithoutStyle from '../../../../../components/DivWithoutStyle'




export function MenuInner() {
  // const intl = useIntl()
  const [user] = useUser();
  const { config } = useLayout()
  return (
    <>
      {
        user.credential ?
          <>
            <MenuItem title={"Início"} to='/homepage/' />
            {

              user.services?.map((item:servicesType, key:number) => {
                return (
                  <DivWithoutStyle key={key}>
                    {
                      item.ativo && item.uuid.includes("2093a3cf-bc98-43d6-b26f-c18bde2e21b4") && user.enterprise?.servicos.includes("2093a3cf-bc98-43d6-b26f-c18bde2e21b4") ?
                        <MenuItem title={item.nome} key={"2093a3cf-bc98-43d6-b26f-c18bde2e21b4"} to='/seed_classifier/dashboard/' />
                        : null
                    }
                    {
                      item.ativo && item.uuid.includes("7e8dc25d-2ebc-4d56-8d7c-4c6d5e048fd7") && user.enterprise?.servicos.includes("7e8dc25d-2ebc-4d56-8d7c-4c6d5e048fd7") ?
                        <MenuItem title={item.nome} key={"7e8dc25d-2ebc-4d56-8d7c-4c6d5e048fd7"} to='#' />
                        : null
                    }

                  </DivWithoutStyle>
                )
              })
            }
          </>
          :
          <div className='app-sidebar-logo'>
            <Link to='/'>
              {config.layoutType === 'dark-sidebar' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default.png')}
                  className='h-25px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default.png')}
                    className='h-25px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default.png')}
                    className='h-25px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}

              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/default-small.png')}
                className='h-20px app-sidebar-logo-minimize'
              />
            </Link>
          </div>
      }




      {/* <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}

    </>
  )
}
