import { RequestUrl } from "../api/index.class";
import Enterprise from "../enterprise/index.class";

export default class User {
    user: userType | null | undefined;

    constructor(user: userType | null | undefined) {
        this.user = user;
    }


    getFullName(): string {
        if (!this.user) return "";
        return this.user.first_name + " " + this.user.last_name;
    }
    hasPermission(permission: string): boolean {
        if (!this.user) return false;
        if (this.user.is_staff) return true;
        return this.user.groups.filter(groupPermission => groupPermission.name.toLowerCase() === permission.toLowerCase()).length > 0;
    }

    getPermissions(): string[] {
        if (!this.user) return [];
        return this.user.groups.map(groupPermission => groupPermission.name);
    }

    isStaff(): boolean {
        if (!this.user) return false;
        return this.user.is_staff;
    }

    getPhoto(completeUrl: boolean = true): string {
        if (!this.user) return this.getDefaultPhoto();
        if (this.user.foto)
            if (completeUrl)
                return RequestUrl + this.user.foto;
            else
                return this.user.foto;
        return this.getDefaultPhoto();
    }

    getDefaultPhoto(): string {
        return '/media/avatars/blank.png';
    }

    getEnterprise() {
        if (!this.user) return null;
        return new Enterprise(this.user.empresa);
    }

    updateUser(user: userType) {
        // access api
    }
}