/* eslint-disable jsx-a11y/anchor-is-valid */


import { ApiClass } from '../../../../../classComponents'
import DatePickerRange from '../../../../../components/toolbar_components/DatePickerRange'

import GrainFilterField from '../../../../../components/toolbar_components/GrainFilterField'
import RegionalFilterField from '../../../../../components/toolbar_components/RegionalFilterField'
import { useUser } from '../../../../../contexts/contexts/UserContext'
const ToolbarSeedClassifier = () => {

  const [user] = useUser()
  
  const api = new ApiClass<any>(user.credential)
 



  
  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3 .rs-theme-dark pt-2' style={{ maxWidth: "100vw", overflowX: "auto" }}>
      {/* <Autocomplete
        disablePortal

        clearIcon={null}
        options={listYears()}
        getOptionLabel={(x: any) => String(x)}
        defaultValue={new Date().getFullYear()}
        onChange={(_, value) => filterDispatch({ type: "SET_YEAR", payload: value })}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} size="small" label="Ano" />}
      /> */}

      <DatePickerRange/>

      <GrainFilterField/>

      <RegionalFilterField />
    </div>
  )
}

export { ToolbarSeedClassifier }

