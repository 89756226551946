import { KTCard } from "../../_metronic/helpers"
import ModalContextWrapper from "../../contexts/contexts/ModalContext"
import { UsersListHeader } from "./users-list/components/header/UsersListHeader"

import { UsersTable } from "./users-list/table/UsersTable"

const UsersList = () => {
    return (
        <KTCard>
                <UsersListHeader />
                <UsersTable />
        </KTCard>
    )
}

export default UsersList