import React, { Dispatch, useContext } from 'react'
import Card from '../../components/card_type_product'
import { useUser } from '../../contexts/contexts/UserContext'
import { WebsocketContext } from '../../contexts/contexts/WebSocketContext'
import { getEnterprise } from '../../services/enterprise'
import { getServicos } from '../../services/servicos'

interface servicesCheckType extends servicesType {
    exist: boolean;
}


function getServicosReuse(user: IUserContext, dispatch: Dispatch<any>) {
    getServicos(user.credential as authType).then(res => {
        let res_convert = res as servicesCheckType[];
        res_convert.map(item => {
            if (user.enterprise?.servicos.includes(item.uuid))
                item.exist = true
            else
                item.exist = false
        })
        dispatch({ type: "SET_SERVICES", payload: res_convert })
    })

    getEnterprise(user.credential as authType).then(res => {
        dispatch({ type: "SET_ENTERPRISE", payload: res })
    })
}

export default function DashBoard() {
    const [user,dispatch] = useUser();
    const socket = useContext(WebsocketContext);
    React.useEffect(() => {
        getServicosReuse(user, dispatch)

        socket.on("update_component", (data:any) => {
            console.log("[Socket client] update_component", data)
            if (data.component === "services") getServicosReuse(user, dispatch)
        })


        return () => {
            socket.off("update_component")
        }
    }, [])


    return (
        <>
            <div>Empresa Logada: {user.enterprise && user.enterprise.nome}</div>
            <div className="row">
                {user.services?.map((item, index) => {
                    if (user.enterprise?.servicos.includes(item.uuid))
                        return <Card key={index} active={item.ativo} image_url={item.imagem ? item.imagem : 'https://www.minutorural.com.br/images/noticias/5525/5ef6fdda5e5c781af0717bc73b919f3f.jpg'} title={item.ativo ? item.nome : item.nome + " (Inativo)"} description={item.descricao} redirect={item.ativo ? `/seed_classifier/dashboard/` : `/${item.uuid}/`} />
                })}

                {user.services?.map((item, index) => {
                    if (!user.enterprise?.servicos.includes(item.uuid))
                        return <Card key={index} active={false} image_url={item.imagem ? item.imagem : 'https://www.minutorural.com.br/images/noticias/5525/5ef6fdda5e5c781af0717bc73b919f3f.jpg'} title={item.nome} description={item.descricao} redirect={`/${item.uuid}/`} />
                })}

            </div>

        </>

    )
}
