/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormEvent } from 'react'
import { useModalContext } from '../../contexts/contexts/ModalContext'
import CharFieldForm from '../fields/Charfield'
import TextAreaField from '../fields/TextAreaField'
import { useUser } from '../../contexts/contexts/UserContext'
import { useApi } from '../../classComponents'
import { toast } from 'react-toastify'
import CheckField from '../fields/CheckField'


const EditMachineForm = () => {
    const [getModalContext, setModalContext] = useModalContext()
    const [user] = useUser()
    const api = useApi( user.credential)
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (getModalContext.type === "PATCH") {
            api.partial_update("maquinas", getModalContext.data.id, {
                nome: e.currentTarget.nome.value,
                maquinaconfiguration: {
                    ativo: e.currentTarget["maquinaconfiguration.ativo"].checked
                }
            }).then((response) => {
                toast.success("Maquina atualizado com sucesso!")
            }).catch((error) => {
                toast.error(error.message)
            })
        }
        setModalContext({ type: "SHOW", payload: false })
    }


    return (
        <form onSubmit={onSubmit}>

            <div className='row mb-10'>
                <div className="col">
                    <CharFieldForm name='nome' title='Nome da Maquina' type="text" required={true} editable={getModalContext.type === "GET" ? false : true} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <CharFieldForm name='identificador' title='Identificador' type="text" required={false} editable={false} defaultValue={getModalContext.data} />
                </div>
            </div>
            <div className="row mb-10">
                <div className="col">
                    <CharFieldForm name='regional.nome' title='Regional Registrada' type="text" required={false} editable={false} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <CharFieldForm name='local' title='Local' type="text" required={false} editable={false} defaultValue={getModalContext.data} />
                </div>
            </div>
            <div className="row mb-10">
                <div className="col">
                    <CheckField name='maquinaconfiguration.ativo' title='Status da maquina' editable={true} defaultValue={getModalContext.data} />
                </div>

            </div>
            {/*<div className="row mb-10">*/}
            {/*    <div className="col">*/}
            {/*        <CharFieldForm name='latitude' title='Latitude Registrada' type="text" required={false} editable={false} defaultValue={getModalContext.data} />*/}
            {/*    </div>*/}
            {/*    <div className="col">*/}
            {/*        <CharFieldForm name='longitude' title='Longitude Registrada' type="text" required={false} editable={false} defaultValue={getModalContext.data} />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="row mb-10">
                <TextAreaField name='graos' title='Grãos Permitidos' type="json" required={false} editable={false} defaultValue={getModalContext.data} />
            </div>
            <div className="row my-3 justify-content-end">
                <div className="col-2">
                    <input type="submit" className='btn btn-lg btn-primary w-100' value={getModalContext.type === "GET" ? "Close" : "Salvar"} />
                </div>
            </div>
        </form>
    )
}
export { EditMachineForm }

