import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Column } from 'react-table';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { ApiClass } from '../../../classComponents';
import { ListTable } from '../../../components';
import ErrorLoading from '../../../components/errorLoading';

import { MachineInfoCell } from '../../../components/list_table/users-list/table/columns/machinesCells/MachineInfoCell';
import { UserCustomHeader } from '../../../components/list_table/users-list/table/columns/usersCells/UserCustomHeader';
import { UserSelectionCell } from '../../../components/list_table/users-list/table/columns/usersCells/UserSelectionCell';
import { UserSelectionHeader } from '../../../components/list_table/users-list/table/columns/usersCells/UserSelectionHeader';
import Loading from '../../../components/loading';
import { BaseModal } from '../../../components/modals/BaseModal';
import { ListComponentWrapper } from '../../../contexts';
import ModalContextWrapper, { useModalContext } from '../../../contexts/contexts/ModalContext';
import { PaginationContextWrapper, usePagination } from '../../../contexts/contexts/PaginationContext';
import { useUser } from '../../../contexts/contexts/UserContext';
import { EditMachineForm } from '../../../components/forms/EditMachineForm';
import { MachineActionCell } from '../../../components/list_table/users-list/table/columns/machinesCells/MachineActionCell';
import { TextCell } from '../../../components/list_table/users-list/table/columns/usersCells/TextCell';

const queryClient = new QueryClient()

const machineBreadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/seed_classifier/dashboard/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const machineColumns: ReadonlyArray<Column<maquinasType>> = [

    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Identificação' className='min-w-125px' />,
        id: 'name',
        Cell: ({ ...props }) => <MachineInfoCell machine={props.data[props.row.index]} />,
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Classificações' className='min-w-125px' />,
        id: 'classificações',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].classificacoes.toString()} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Ativo' className='min-w-125px' />,
        id: 'ativo',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].maquinaconfiguration.ativo.toString()} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Regional' className='min-w-125px' />,
        id: 'regional',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].regional?.nome} />
    },
    {
        Header: (props: PropsWithChildren<any>) => (
            <UserCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({ ...props }) => <MachineActionCell item={props.data[props.row.index] as {}} />,
    },
]
export default function MachinePageWrapper() {
    return (
        <QueryClientProvider client={queryClient}>
            <ModalContextWrapper defaultValue={{ form: EditMachineForm, show: false, title: "Maquina Registrada", data: undefined, type: 'GET' }}>
                <PaginationContextWrapper>
                    <MachinePage />
                </PaginationContextWrapper>
            </ModalContextWrapper>
        </QueryClientProvider>
    )
}


export function MachinePage() {

    const [user] = useUser();
    const [pagination] = usePagination()
    const [getModalContext, setModalContext] = useModalContext()
    const api = new ApiClass<maquinasType>(user.credential)
    const { data, isLoading, error } = useQuery(`machines-list-${JSON.stringify(pagination)}`, async (): Promise<maquinasType[] | maquinasType | IPagination<maquinasType>> => api.get("maquinas", { ...pagination }))

    if (error) return <ErrorLoading error={"Não foi possível buscar as maquinas"} objectError={error} />

    return (
        <>
            {
                isLoading && <Loading overlay={true} />
            }
            <PageTitle breadcrumbs={machineBreadcrumbs}>Maquinas</PageTitle>
            <ListComponentWrapper content={data !== undefined ? ({ ...((data as IPagination<maquinasType>).results !== undefined ? (data as IPagination<maquinasType>) : { results: (data as maquinasType[]) }), columns: machineColumns }) : ({ ...({ results: ([]) }), columns: machineColumns })}>
                <ListTable />
            </ListComponentWrapper>
            <BaseModal title={getModalContext?.title} show={getModalContext.show} handleClose={() => setModalContext({ type: "SHOW", payload: false })}>
                {getModalContext?.form ? <getModalContext.form /> : null}
            </BaseModal>
        </>
    )
}