import { CustomProvider, DatePicker, InputGroup } from 'rsuite'
import moment from 'moment'
import ptBR from 'rsuite/locales/pt_BR'
import { RangeType } from 'rsuite/esm/DatePicker'
import { useFilterContext } from '../../contexts/contexts/FilterContext'

export default function DatePickerRange() {
    const [filter, filterDispatch] = useFilterContext()
    const predefinedRanges: RangeType<Date>[] = [
        {
            label: 'today',
            value: moment().toDate(),
            placement: 'left'
        },
        {
            label: 'yesterday',
            value: moment().subtract(1, "day").toDate(),
            placement: 'left'
        },
        {
            label: '7 Dias',
            value: moment().subtract(7, 'days').toDate(),
            placement: 'left'
        }, {
            label: '30 Dias',
            value: moment().subtract(30, 'days').toDate(),
            placement: 'left'
        },
        {
            label: '3 Meses',
            value: moment().subtract(3, 'months').toDate(),
            placement: 'left'
        },
        {
            label: '1 Ano',
            value: moment().subtract(1, 'year').toDate(),
            placement: 'left'
        },
    ];
    return (
        <CustomProvider theme='dark' locale={ptBR}>
            <InputGroup style={{ width: 400 }} >
                <DatePicker
                    format="dd/MM/yyyy"
                    block
                    cleanable={false}
                    oneTap
                    appearance="subtle"
                    style={{ width: 200 }}
                    value={moment(filter.created_at_after, "yyyy-M-D").toDate()}
                    onChange={(value) => { filterDispatch({ type: "SET_CREATED_AT_AFTER", payload: moment(value).format("yyyy-M-D") }) }}
                    ranges={predefinedRanges} />
                <InputGroup.Addon>-</InputGroup.Addon>
                <DatePicker format="dd/MM/yyyy"
                    block
                    oneTap
                    cleanable={false}
                    appearance="subtle"
                    style={{ width: 200 }}
                    value={moment(filter.created_at_before, "yyyy-M-D").toDate()}
                    ranges={predefinedRanges}
                    onChange={(value) => { filterDispatch({ type: "SET_CREATED_AT_BEFORE", payload: moment(value).format("yyyy-M-D") }) }} />
            </InputGroup>
        </CustomProvider>
    )
}