
export default function PaginationReducer(pagination: PaginationContextType, action: any) {
    switch (action.type) {
        case "SET_OFFSET":
            return { ...pagination, offset: parseInt(action.payload) }
        case "SET_LIMIT":
            return { ...pagination, limit: parseInt(action.payload) }
        case "SET_SEARCH":
            return { ...pagination, search: action.payload, offset: 0 }
        case "SET_DATERANGE":
            return { ...pagination, created_at_after: action.payload?.created_at_after, created_at_before: action.payload?.created_at_before, offset: 0 }
        case "SET_MACHINE_ID":
            return { ...pagination, maquina_id: action.payload, offset: 0 }
        case "SET_PRODUCT_ID":
            return { ...pagination, produto: action.payload, offset: 0 }
        case "RESET":
            return {}
        case "SET":
            return action.payload
        default:
            return pagination
    }
}