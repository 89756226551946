
export default class Enterprise {
    enterprise: enterpriseType;

    constructor(enterprise: enterpriseType | null | undefined) {
        if (enterprise === null || enterprise === undefined) throw new Error("Enterprise is null");
        this.enterprise = enterprise;
    }

    hasService(service: string): boolean {
        return this.enterprise.servicos.includes(service);
    }

}