import { createContext, useContext, useReducer } from "react";
import UserReducer from "../reducers/UserReducer";


export const UserContext = createContext<ContextType<IUserContext>>([{}, () => { }])


export function UserContextWrapper({ children }: any) {
    const [user, dispatch] = useReducer(UserReducer, {
        credential: window.localStorage.getItem("a6a24033-80f8-493c-9252-7361f6f85b41") ? JSON.parse(window.localStorage.getItem("a6a24033-80f8-493c-9252-7361f6f85b41") || "{}") : null,
        profile: window.localStorage.getItem("f1631e21-2772-4529-b3a1-f46594a57f0d") ? JSON.parse(window.localStorage.getItem("f1631e21-2772-4529-b3a1-f46594a57f0d") || "{}") : null,
        enterprise: window.localStorage.getItem("f3e6eb95-b809-48a9-9219-758a3215b071") ? JSON.parse(window.localStorage.getItem("f3e6eb95-b809-48a9-9219-758a3215b071") || "{}") : null,
        services: window.localStorage.getItem("3dc922f6-3e6b-4747-a3b9-bdbcef048be8") ? JSON.parse(window.localStorage.getItem("3dc922f6-3e6b-4747-a3b9-bdbcef048be8") || "{}") : null,
    })
    return (
        <UserContext.Provider value={[user, dispatch]}>
            {children}
        </UserContext.Provider>
    )
}

export function useUser() {
    return useContext(UserContext)
}