import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiClass } from "../../classComponents";
import { useUser } from "../../contexts/contexts/UserContext";


export default function AuthenticatedRoutes() {

    const [user,dispatch] = useUser()
    const api = new ApiClass<any>(user.credential);

    const checkAuth = () => {

        if (!user.credential && !user.profile) {
            dispatch({ type: "RESET" })
            return <Navigate to="/login/" replace={true} />
        }

        api.create(["token", "verify"], { token: api.auth?.access }).catch(err => {
            dispatch({ type: "RESET" })
            toast.error("Seu token não é válido! Faça login novamente.")
            return <Navigate to="/login/" replace={true} />
        })

        return <Outlet />
    }

    return (
        <>
            {checkAuth()}
        </>

    )
}