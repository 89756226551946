import { useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { BaseModal } from '../../../../modals/BaseModal'
import { useListView } from '../../core/ListViewProvider'
import { UsersListFilter } from './UsersListFilter'
import { EditUserForm } from '../../../../forms/EditUserForm'

import React from 'react'
import { useModalContext } from '../../../../../contexts/contexts/ModalContext'



const UsersListToolbar = (props: any) => {
  // const { setItemIdForUpdate } = useListView()
  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null)
  // }
  const [getModalContext, ] = useModalContext()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3' disabled>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/*TODO:  begin::Add user  */}
      {getModalContext?.toolbar}

      {/* end::Add user */}
    </div>
  )
}

export { UsersListToolbar }
