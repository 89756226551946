/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { useModalContext } from '../../../../../../contexts/contexts/ModalContext'

type Props = {
    item: any;
}

const SampleActionCell: FC<Props> = ({ item }) => {
    const [, setModalContext] = useModalContext()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])


    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                Actions
                <KTIcon iconName='down' className='fs-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={() => { setModalContext({ type: "SET", payload: { show: true, data: item, title: "Visualizar Amostra", type: 'PATCH' } }) }}>
                        Visualizar
                    </a>
                </div>
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={() => { window.open(`/seed_classifier/report/${item.id}/`, "_blank") }}>
                        Relatório
                    </a>
                </div>


                {/* end::Menu item */}

                {/* begin::Menu item */}
                {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => { }}
          >
            Delete
          </a>
        </div> */}
                {/* end::Menu item */}
            </div>
            {/* end::Menu */}
        </>
    )
}

export { SampleActionCell }
