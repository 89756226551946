import { Skeleton } from "@mui/material";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../classComponents";
import { useFilterContext } from "../../contexts/contexts/FilterContext";
import { useUser } from "../../contexts/contexts/UserContext";
import colorsAvarias from "../../utils/colorsAvarias";
import ErrorLoading from "../errorLoading";
import Loading from "../loading";
import { GrainFilterFieldLocal } from "../toolbar_components/GrainFilterField";

type AvariasRowProps = {
    className?: string;
    name: string;
    percent: number | string;
    color?: string;
    max?: number;
}

const maxAtt = (obj: any): number => {
    let max: number = 0;
    for (const key in obj)
        if (parseFloat(obj[key]) >= max) max = parseFloat(obj[key])
    return max
}

const range = (start: number, end: number) => Array.from(Array(end - start + 1).keys()).map(x => x + start);
const AvariasRow: React.FC<AvariasRowProps> = ({ name, percent = 0, max, color = "red", className = "" }) => {
    return (
        <tr className={"border-0 " + className}>
            <td className="pt-3">
                <div className='d-flex align-items-center justify-content-center'>
                    {name}
                </div>
            </td>
            <td className='text-end p-0 '>
                <div className='d-flex flex-column w-75'>
                    <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>{percent}%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                        <div
                            className={`progress-bar`}
                            role='progressbar'
                            style={{ width: `${max ? (parseFloat(percent.toString()) / max) * 100 : percent}%`, backgroundColor: color }}
                        ></div>
                    </div>
                </div>
            </td>
        </tr>
    )
}

const AvariasList: React.FC<any> = () => {
    const [user] = useUser();
    const [filter] = useFilterContext()
    const localGrainFilter = useState<{ produto: string | undefined }>({ produto: undefined })
    const api = useApi(user.credential)
    const { data, isLoading, error, } = useQuery(`list-${JSON.stringify(filter)}-${localGrainFilter[0].produto}`,
        () => {
            if (filter.produto) return api.get(["graph", "get_avaria_median"], { ...filter })
            else if (!filter.produto && localGrainFilter[0].produto) return api.get(["graph", "get_avaria_median"], { ...filter, produto: localGrainFilter[0].produto })
            else return []
        }

    )
    const functionMaxAtt = useMemo(() => maxAtt(data), [data])

    if ((filter.produto === undefined || filter.produto === null) && (localGrainFilter[0].produto === undefined || localGrainFilter[0].produto === null)) return (
        <div className="d-flex justify-content-center h-100 align-items-center">
            {/* {range(0, 9).map((item, i) => {
                return <Skeleton key={i} className="mb-5 mx-auto" variant="rectangular" width={"95%"} height={25} />
            })} */}
            <div className="d-flex justify-content-center flex-column p-8 rounded " style={{ backgroundColor: "var(--primary-color)" }}>
                <h6 className="text-white text-center mb-2">Selecione um tipo de grão {localGrainFilter[0].produto}</h6>
                <GrainFilterFieldLocal state={localGrainFilter} />
            </div>
        </div>
    )
    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error="Erro ao carregar a lista de avarias" objectError={error} />

    return (
        <div className='table-responsive .rs-theme-dark '>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                    <tr className='fw-bold text-muted text-center border-0'>
                        <th className='min-w-50px p-0'></th>
                        <th className='min-w-150px p-0'></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (filter.produto === undefined || filter.produto === null) && localGrainFilter[0].produto ?
                            <tr className={"border-0 "}>
                                <td className="pt-3">
                                    <div className='d-flex align-items-center justify-content-center'>
                                    </div>
                                </td>
                                <td className='text-end p-0 '>
                                    <div className='d-flex flex-column w-75 '>
                                        <button className="badge rounded-pill bg-danger w-50 align-self-end btn btn-danger text-center py-1 px-3" onClick={() => localGrainFilter[1]({ produto: undefined })}><p className="text-center w-100">Rem. Filtro</p></button>
                                    </div>
                                </td>
                            </tr> : null
                    }
                    {
                        Object.keys(data).sort((a: string, b: string) => data[a] - data[b]).map((item: string, i: number) => {
                            return <AvariasRow key={i} name={item} max={functionMaxAtt} percent={(parseFloat(data[item])).toFixed(3)} color={colorsAvarias.find(color => color.name == item)?.color} />
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export { AvariasList };

