/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import moment from 'moment';
import { FC } from 'react'

type Props = {
  sample: sampleType;
  onClick?: () => void;
}

const SampleInfoCell: FC<Props> = ({ sample, onClick }) => {
  if (sample === undefined) return <></>
  return (
    <div className='d-flex align-items-center' >
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href={`#${sample.processo_id}`} onClick={onClick}>
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${sample.produto?.name[0]}`,
              `text-${sample.produto?.name[0]}`
            )}
          >
            {(sample.produto?.name[0] || "?") + (sample.produto?.name[1] || "?")}
          </div>
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href={`#${sample.processo_id}`} onClick={onClick} className='text-gray-800 text-hover-primary mb-1'>
          {sample.processo_id || "Nenhum Id"}
        </a>
        <span>{moment(sample.created_at).format("DD/MM/YYYY HH:MM") || ""}</span>
      </div>
    </div>
  )
}

export { SampleInfoCell }
