import moment from 'moment';
import React from 'react'

type Props = {
    name: string;
    title?: string;
    defaultValue?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    errorMessage?: string;
    hasError?: boolean;
    required?: boolean;
    editable?: boolean;
}

export default function DateTimeField({ name, title, defaultValue = {}, onChange, errorMessage, placeholder, hasError, required, editable = true }: Props) {
    const [value, setValue] = React.useState<any>(defaultValue[name] ? moment(defaultValue[name]).format("DD/MM/YYYY hh:mm:ss") : moment().format("DD/M/YYYY hh:mm:ss"));

    return (
        <div>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className={required ? 'required' : ''}>{title ? title : name}</span>
                <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title={title}
                ></i>
            </label>
            <input
                type={'text'}
                className='form-control form-control-lg form-control-solid'
                name={name}
                disabled={defaultValue[name] && !editable}
                required={required}
                placeholder={placeholder}
                value={value}
                onChange={onChange ? onChange : (e) => { setValue(e.target.value) }}
            />
            {!defaultValue && hasError && (
                <div className='fv-plugins-message-container'>
                    <div data-field={name} data-validator='notEmpty' className='fv-help-block'>
                        {errorMessage}
                    </div>
                </div>
            )}
        </div>
    )
}
