/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useContext, useMemo } from 'react'
import { listContext } from '../../../../../contexts/contexts/ListContext'
import { usePagination } from '../../../../../contexts/contexts/PaginationContext'
import { useQueryResponseLoading } from '../../core/QueryResponseProvider'
import React from 'react'


type sliceLinksLimitOffsetType = {
  offset: number;
  limit: number;
  count: number;
}


const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const ListPaginationOffsetLimit = () => {
  const [pagination, dispatch] = usePagination()
  const paginationData = useContext(listContext)
  const isLoading = useQueryResponseLoading()

  const updateOffset = (offset: number | undefined | null) => {
    if (offset === undefined || offset === null) return
    dispatch({ type: 'SET_OFFSET', payload: offset })
  } 

  const count = paginationData.count ? paginationData.count : 0
  const calculeLastPage = (count:number,limit:number) => Math.ceil(count / limit)-1
  const calculeActualPage = (offset:number,limit:number) => Math.ceil(offset / limit)

  const lastPage = useMemo(():number => calculeLastPage(count,pagination.limit),[pagination])
  const actualPage = useMemo(():number => calculeActualPage(pagination.offset,pagination.limit),[pagination])

  const sliceLinksLimitOffset = ({ offset, limit, count }: sliceLinksLimitOffsetType) => {
    if (count <= 0 || limit <= 0) return []

    let pageLinks: Array<{
      label: string
      active: boolean
      url: number | null
    }> = []

    console.log(pagination,paginationData.count)
    console.log(actualPage,lastPage)

    if (count / limit <= 3) {
      for (let i = 0; i < count / limit; i++) {
        pageLinks.push({ label: (i).toString(), active: true, url: i * limit })
      }
      return pageLinks
    }


    if (actualPage === 0) {
      pageLinks.push({ label: actualPage.toString(), active: true, url: null })
      pageLinks.push({ label: (actualPage + 1).toString(), active: true, url: offset + limit })
      pageLinks.push({ label: (actualPage + 2).toString(), active: true, url: offset + limit * 2 })
      return pageLinks
    }

    if (actualPage === lastPage) {
      pageLinks.push({ label: (actualPage - 2).toString(), active: true, url: offset - limit * 2 })
      pageLinks.push({ label: (actualPage - 1).toString(), active: true, url: offset - limit })
      pageLinks.push({ label: actualPage.toString(), active: true, url: null })
      return pageLinks
    }

    if (actualPage === 0) {
      pageLinks.push({ label: actualPage.toString(), active: true, url: null })
      pageLinks.push({ label: (actualPage + 1).toString(), active: true, url: offset + limit })
      pageLinks.push({ label: (actualPage + 2).toString(), active: true, url: offset + limit * 2 })
      return pageLinks
    } else {
      pageLinks.push({ label: (actualPage - 1).toString(), active: true, url: offset - limit })
      pageLinks.push({ label: actualPage.toString(), active: true, url: null })
      pageLinks.push({ label: (actualPage + 1).toString(), active: true, url: offset + limit })
      return pageLinks
    }
  }



  const paginationLinks = useMemo(() => sliceLinksLimitOffset({ offset: pagination.offset, limit: pagination.limit, count: paginationData.count ? paginationData.count : 0 }), [pagination])

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.offset <= 9,
              })}
            >
              <a onClick={() => updateOffset(0)} style={{ cursor: 'pointer' }} className='page-link'>
                First
              </a>
            </li>
            {paginationLinks
              ?.map((link) => {
                return { ...link, label: mappedLabel(link.label) }
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: actualPage === parseInt(link.label),
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updateOffset(link.url)}
                    style={{ cursor: 'pointer' }}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
            <li
              className={clsx('page-item', {
                disabled: (isLoading || pagination.offset === (lastPage * pagination.limit) || lastPage <= 1),
              })}
            >
              <a
                onClick={() => updateOffset(lastPage*pagination.limit)}
                style={{ cursor: 'pointer' }}
                className='page-link'
              >
                Last
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { ListPaginationOffsetLimit }

