import api from "../api";

export async function getEnterprises(auth: authType): Promise<enterpriseType[]> {
    return await api({ method: 'GET', path: '/empresas/', auth});
}


export async function createEnterprise(auth: authType, enterprise: enterpriseCreateType): Promise<enterpriseType> {
    return await api({ method: 'POST', path: '/empresas/', auth, body: enterprise });
}

export async function getEnterprise(auth: authType): Promise<enterpriseType> {
    return await api({ method: 'GET', path: '/empresas/get_enterprise/', auth });
}