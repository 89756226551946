import moment from 'moment'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { ApiClass } from '../../classComponents'
import { useFilterContext } from '../../contexts/contexts/FilterContext'
import { useUser } from '../../contexts/contexts/UserContext'
import ErrorLoading from '../errorLoading'
import Loading from '../loading'


export default function Chart5() {
    const [filter] = useFilterContext()
    const [user] = useUser();
    const api = new ApiClass<any>(user.credential)
    // const [filterRange, setFilterRange] = useState<[moment.Moment, moment.Moment]>([filter.created_at_before ? moment(filter.created_at_before, "yyyy-M-D") : moment().subtract(7, "days"), filter.created_at_after ? moment(filter.created_at_after, "yyyy-M-D") : moment()])

    const { data, isLoading, error, refetch } = useQuery(`getCountGraficos-${JSON.stringify({
        ...filter,
        created_at_before: filter.created_at_before,
        created_at_after: filter.created_at_after
    })}`, 
    
    () => api.get(["graph", "get_count"], {
        ...filter,
        created_at_before: filter.created_at_before,
        created_at_after: filter.created_at_after
    }))


    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error="Erro ao carregar o Chart5" objectError={error} />
    return (
        <div className="container">
            <div className="row text-center my-10">
                <h1 style={{ fontSize: "4rem" }}>{data?.todos}</h1>
               
            </div>
            <div className="row text-end w-100 position-absolute bottom-0 ">
                <h1 style={{ fontSize: "1rem",lineHeight:"normal" }}> De { moment(filter.created_at_after, "yyyy-M-D").format("D/M/yyyy") } até {moment(filter.created_at_before, "yyyy-M-D").format("D/M/yyyy")} </h1>
            </div>
        </div>
    )
}
