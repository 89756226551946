import { Dispatch, createContext, useReducer } from "react";

interface listContextProps {
    columns: any,
    results: any[],
    count?: number,
    next?: string | null,
    previous?: string | null,
}

type ListContextType = {
    content?: listContextProps;
    children: any;
}

const defaultValues = {
    columns: [],
    results: [],
    count: 0,
    next: null,
    previous: null,
}

export const listContext = createContext<listContextProps>(defaultValues)

const ListContextWrapper = ({ children, content }: ListContextType) => {
    return (
        <listContext.Provider value={content?.columns ? content : defaultValues}>
                {children}
        </listContext.Provider>
    )
}

export default ListContextWrapper