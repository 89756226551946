import React, { PropsWithChildren, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Column } from 'react-table';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { ApiClass } from '../../../classComponents';
import { ListTable } from '../../../components';
import ErrorLoading from '../../../components/errorLoading';

import { Autocomplete, Box, Slider, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useDebounce } from '../../../_metronic/helpers';
import { GetSampleForm } from '../../../components/forms/GetSampleForm';
import { SampleActionCell } from '../../../components/list_table/users-list/table/columns/samplesCells/SampleActionCell';
import { SampleInfoCell } from '../../../components/list_table/users-list/table/columns/samplesCells/SampleInfoCell';
import { TextCell } from '../../../components/list_table/users-list/table/columns/usersCells/TextCell';
import { UserCustomHeader } from '../../../components/list_table/users-list/table/columns/usersCells/UserCustomHeader';
import Loading from '../../../components/loading';
import { BaseModal } from '../../../components/modals/BaseModal';
import { ListComponentWrapper } from '../../../contexts';
import ModalContextWrapper, { useModalContext } from '../../../contexts/contexts/ModalContext';
import { PaginationContextWrapper, usePagination } from '../../../contexts/contexts/PaginationContext';
import { useUser } from '../../../contexts/contexts/UserContext';

const queryClient = new QueryClient()

const samplesBreadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/seed_classifier/dashboard/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export default function SamplePageWrapper() {
    return (
        <QueryClientProvider client={queryClient}>
            <ModalContextWrapper defaultValue={{ form: GetSampleForm, show: false, title: "Amostra Registrada", data: undefined, type: 'GET' }}>
                <PaginationContextWrapper>
                    <SamplePage />
                </PaginationContextWrapper>
            </ModalContextWrapper>
        </QueryClientProvider>
    )
}


function convertNumberToDate(x: number, startDate: moment.Moment): moment.Moment {
    return startDate.clone().add(x, "days")
}

function machineItems(list: maquinasType[], null_value: boolean = false): { label: string, value: number | null }[] {
    let result: { label: string, value: number | null }[] = []
    if (null_value) result.push({ label: "Nenhum", value: null })
    list.forEach(item => { result.push({ label: item.nome ? item.nome : item.identificador, value: item.id }) })
    return result
}

function productItems(list: ProductType[], null_value: boolean = false): { label: string, value: number | null }[] {
    let result: { label: string, value: number | null }[] = []
    if (null_value) result.push({ label: "Nenhum", value: null })
    list.forEach(item => { result.push({ label: item.name, value: item.id }) })
    return result
}

const startDate = moment().subtract(6,"month");

export function SamplePage() {

    const samplesColumns: ReadonlyArray<Column<sampleType>> = [
        {
            Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Identificação' className='min-w-125px' />,
            id: 'name',
            Cell: ({ ...props }) => <SampleInfoCell onClick={() => { setModalContext({ type: "SET", payload: { show: true, data: props.data[props.row.index] } }) }} sample={props.data[props.row.index]} />,
        },

        {
            Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Produto' className='min-w-125px' />,
            id: 'produto',
            Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].produto?.name} />
        },
        {
            Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Descartada' className='min-w-125px' />,
            id: 'descartada',
            Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].descartada.toString()} />
        }, {
            Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Classificador' className='min-w-125px' />,
            id: 'created_at',
            Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].classificador} />
        },
        {
            Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Criado em' className='min-w-125px' />,
            id: 'classificador',
            Cell: ({ ...props }) => <TextCell text={moment(props.data[props.row.index].created_at).format("DD/MM/YYYY hh:mm")} />
        },
        {
            Header: (props: PropsWithChildren<any>) => (
                <UserCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
            ),
            id: 'actions',
            Cell: ({ ...props }) => <SampleActionCell item={props.data[props.row.index] as {}} />,
        },

    ]


    const [user] = useUser();
    const [getModalContext, setModalContext] = useModalContext()
    const [pagination, dispatchPagination] = usePagination()
    const api = new ApiClass<any>(user.credential)
    const { data, isLoading, error } = useQuery(`samples-list-${JSON.stringify(pagination)}`, async (): Promise<userType[] | userType | IPagination<userType>> => api.get("amostras", { ...pagination }))
    const machine_data = useQuery(`machines-list-`, async (): Promise<maquinasType[] | maquinasType | IPagination<maquinasType>> => api.get("maquinas"))
    const product_data = useQuery(`product-list-`, async (): Promise<ProductType[] | ProductType | IPagination<ProductType>> => api.get("graos"))

    const [value, setValue] = React.useState<number[]>([1, moment().diff(startDate, "days")]);
    const debouncedSearchTerm = useDebounce(value, 1000)

    useEffect(
        () => {
            if (debouncedSearchTerm !== undefined && value !== undefined)
                dispatchPagination({ type: "SET_DATERANGE", payload: { created_at_before: convertNumberToDate(value[1], startDate).format("YYYY-MM-DD"), created_at_after: convertNumberToDate(value[0], startDate).format("YYYY-MM-DD") } })
        }, [debouncedSearchTerm,dispatchPagination,value])

    useEffect(() => {
        setModalContext({
            type: "SET_TOOLBAR", payload: (
                <div className='row'>
                    {
                        product_data.isLoading ? null :
                            <Box sx={{ width: 250 }}>
                                <Autocomplete
                                    disablePortal
                                    clearIcon={null}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    options={productItems((product_data.data as ProductType[]).length > 0 ? (product_data.data as ProductType[]) : [], true)}
                                    getOptionLabel={(x: { label: string, value: number | null }) => x.label}
                                    defaultValue={{ label: "nenhuma", value: null }}
                                    onChange={(_, value) => { dispatchPagination({ type: "SET_PRODUCT_ID", payload: value?.value }) }}
                                    sx={{ width: 200 }}
                                    renderInput={(params) => <TextField {...params} size="small" label="Grão" />}
                                />
                            </Box>
                    }
                    {
                        machine_data.isLoading ? null :
                            <Box sx={{ width: 250 }}>
                                <Autocomplete
                                    disablePortal
                                    clearIcon={null}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    options={machineItems((machine_data.data as maquinasType[]).length > 0 ? (machine_data.data as maquinasType[]) : [], true)}
                                    getOptionLabel={(x: { label: string, value: number | null }) => x.label}
                                    defaultValue={{ label: "nenhuma", value: null }}
                                    onChange={(_, value) => { dispatchPagination({ type: "SET_MACHINE_ID", payload: value?.value }) }}
                                    sx={{ width: 200 }}
                                    renderInput={(params) => <TextField {...params} size="small" label="Maquina" />}
                                />
                            </Box>
                    }

                    <Box sx={{width:350}} >
                        <Typography className='text-center' id="non-linear-slider" gutterBottom>
                            Busca entre: {convertNumberToDate(value[0], startDate).format("DD/MM/YYYY")} - {convertNumberToDate(value[1], startDate).format("DD/MM/YYYY")}
                        </Typography>
                        <Slider
                            getAriaLabel={() => 'Temperature range'}
                            color="primary"
                            value={value}
                            max={moment().diff(startDate, "days")}
                            min={0}
                            onChange={(event: Event, newValue: number | number[], activeThumb: number) => {
                                if (!Array.isArray(newValue))
                                    return;
                                if (activeThumb === 0) {
                                    setValue([newValue[0], value[1]]);
                                } else {
                                    setValue([value[0], newValue[1]]);
                                }
                            }}
                            valueLabelDisplay="off"
                            getAriaValueText={(value: number) => "value"}
                        />
                    </Box>

                </div>
            )
        })
    })
    // if (isLoading) return <Loading />
    if (error) return <ErrorLoading error={"Não foi possível buscar as amostras"} objectError={error} />

    return (
        <>
            {
                isLoading && <Loading overlay={true} />
            }
            <PageTitle breadcrumbs={samplesBreadcrumbs}>Amostras</PageTitle>
            <ListComponentWrapper content={data !== undefined ? ({ ...((data as IPagination<userType>).results !== undefined ? (data as IPagination<userType>) : { results: (data as userType[]) }), columns: samplesColumns }) : ({ ...({ results: ([]) }), columns: samplesColumns })}>
                <ListTable />
            </ListComponentWrapper>
            <BaseModal title={getModalContext?.title} show={getModalContext.show} handleClose={() => setModalContext({ type: "SHOW", payload: false })}>
                {getModalContext?.form ? <getModalContext.form /> : null}
            </BaseModal>
        </>
    )
}