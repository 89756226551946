import { createContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { ApiClass } from '../../classComponents';
import { useUser } from './UserContext';


// "undefined" means the URL will be computed from the `window.location` object
const SocketURL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:8081';

export const WebsocketContext = createContext<any>({});

export default function WebsocketContextWrapper({ children }: any) {
    const socket = useRef(io(SocketURL, {
        transports: ['websocket']
    }));
    const [user, dispatch] = useUser();
    const api = new ApiClass(user.credential)

    useEffect(() => {
        socket.current.on("connect", () => {
            console.log("[Socket client] connected")
            socket.current.emit("connect_user", {
                enterprise: user.enterprise?.id,
                user: (user.profile as userType).id
            })
        })

        socket.current.on("disconnect", () => {
            console.log("[Socket client] disconnected")
        })
        socket.current.on("connect_error", (error) => {
            // toast.error(error.message)
            console.log("[Socket client] error", error)
        })
        socket.current.on("error", (data: any) => {
            toast.error(data.message)
        })

        socket.current.on("update_component", (data: any) => {
            console.log("[Socket client] update_component", data)
            if (data.component === "profile") api.get(["users", "get_profile"]).then(data => {
                console.log(data)
                toast.info("perfil atualizado")
                dispatch({ type: "SET_PROFILE", payload: data })
            });
        })

        return () => {
            socket.current.off("connect")
            socket.current.off("disconnect")
            socket.current.off("connect_error")
            socket.current.off("error")
            socket.current.off("update_component")
            console.log("[Socket client] disconnected")
        }
    }, [])
    return (
        <WebsocketContext.Provider value={socket.current}>
            {children}
        </WebsocketContext.Provider>
    )
}