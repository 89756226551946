import React from 'react'

export default function ErrorPage404() {
  return (
    <div className="text-white" style={{ backgroundColor: "var(--secondary-color)", width: "100vw", height: "100vh" }}>
      <div className='container'>
        <h2 className="text-center p-5" data-content="404">
          404 - Página não encontrada
        </h2>

        <h3 className="text-center mb-3" style={{ color: "var(--primary-color)" }}>
          Ooops, parece que você não tem permissão para acessar esta página.
        </h3>
        <h3 className="text-center mb-3" style={{ color: "var(--primary-color)" }}>
          Tente contatar o administrador do sistema para que seja atribuída permissão de acesso.
        </h3>

        <div className="mt-5 w-100 d-flex justify-content-center">
          <a className="btn btn-success p-3" style={{ backgroundColor: "var(--primary-color)" }} href="/">Voltar para página principal</a>
        </div>
      </div>

    </div>
  )
}

