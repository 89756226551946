import { createStore, useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
const store = createStore()

export const userCredential = atomWithStorage<authType|null>('tokenAccess', null)
export const userProfile = atomWithStorage<userType|null>('userProfile', null)
export const userEnterprise = atomWithStorage<enterpriseType|null>('userEnterprise', null)
export const EnterpriseServices = atomWithStorage<servicesType[]>('enterpriseServices', [])

store.set(userCredential, null)
store.set(userProfile, null)
store.set(userEnterprise, null)

export default store