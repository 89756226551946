import React from 'react'
import { toast } from 'react-toastify';

type Props = {
    error?:string;
    objectError?:any
}
export default function ErrorLoading({error,objectError}:Props) {
    React.useEffect(() => {
        if (error)
        toast.error(error)
        console.log(objectError)
    },[])
    return (
        <div className='container d-flex justify-content-center ' style={{ height: "100%", width: "100%" }}>
            <div className="spinner-grow text-danger my-auto" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}
