import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React from 'react';

export default function EnterpriseForm() {


    return (
        <div className='w-75 mx-auto'>
            <h3 className='text-center text-white'>Criando uma nova empresa</h3>
            <TextField type='text' name="nome" fullWidth label="Nome" variant="outlined" size='small' sx={{ background: "white" }} required margin='normal' />
            <TextField type='text' name="cnpj" fullWidth label="CNPJ" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 13 }} variant="outlined" size='small' sx={{ background: "white" }} required margin='normal' />
            <TextField type='text' name="cidade" fullWidth label="Cidade" variant="outlined" size='small' sx={{ background: "white" }} required margin='normal' />
            <TextField type='text' name="estado" fullWidth inputProps={{ maxLength: 2 }} label="Estado" variant="outlined" size='small' sx={{ background: "white" }} required margin='normal' />
        </div>
    )
}
