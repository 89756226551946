import moment from "moment";
import React, { Dispatch, useContext, useMemo } from "react";
import FilterReducer from "../reducers/FilterReducer";
export const FilterContext = React.createContext<[IFilterContext, Dispatch<any>]>([{}, () => { }]);


const FilterContextWrapper = ({ children }: any) => {
    const [filter, dispatch] = React.useReducer(FilterReducer, { created_at_before: moment().format("yyyy-M-D"), created_at_after: moment().format("yyyy-1-1") });
    const filterMemo = useMemo(() => filter, [filter])
    return (
        <FilterContext.Provider value={[filterMemo, dispatch]} >
            {children}
        </FilterContext.Provider>
    )
}

export function useFilterContext() {
    return useContext(FilterContext)
}


export default FilterContextWrapper