const colorsAvarias = [{
    "name": "sadio",
    "color": "#42f023"
},
{
    "name": "mofado",
    "color": "#f59331"
},
{
    "name": "queimado",
    "color": "#fafa37"
},
{
    "name": "fermentado",
    "color": "#b83df5"
},
{
    "name": "ardido",
    "color": "#131310"
},
{
    "name": "imaturo",
    "color": "#32b7fa"
},
{
    "name": "chocho",
    "color": "#aaf0d1"
},
{
    "name": "carunchado",
    "color": "#8ba152"
},
{
    "name": "gessado",
    "color": "#a55194"
},
{
    "name": "chocho_imaturo",
    "color": "#bcbd21"
},
{
    "name": "germinado",
    "color": "#ff0000"
},
{
    "name": "tratado",
    "color": "#076dcb"
}
]

export default colorsAvarias