export const RequestUrl = (process.env.REACT_APP_REQUEST_URL || 'http://localhost:8080')
export const requestUrlApi = RequestUrl + "/api";

console.log(process.env.REACT_APP_REQUEST_URL)

export default class Api<T>{
    url: string;
    auth: authType | undefined;
    pagination?: boolean;


    constructor(auth?: authType) {
        this.url = requestUrlApi;
        if (auth)
            this.auth = auth;
    }

    public async get(path: string | string[], filter?: any): Promise<T[] | T | IPagination<T>> {
        const response = await fetch(this.url + "/" + this.formatterPathResponse(path) + "/" + this.formatterFilterRequest(filter), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.auth ? 'Bearer ' + this.auth.access : '',
            }
        })
        if (!response.ok) {
            this.errorTreatment(response)
        }

        const jsonResponse = await response.json()
        return jsonResponse
    }

    public async retrieve(path: string | string[], id: string): Promise<T> {
        const response = await fetch(this.url + "/" + this.formatterPathResponse(path, id) + "/", {
            method: 'GET',
            headers: this.headerRequest()
        })
        if (!response.ok) {
            this.errorTreatment(response)
        }
        const jsonResponse = await response.json()
        return jsonResponse
    }

    public async create(path: string | string[], data: T): Promise<T> {
        const response = await fetch(this.url + "/" + this.formatterPathResponse(path) + "/", {
            method: 'POST',
            headers: this.headerRequest(),
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            this.errorTreatment(response)
        }
        const jsonResponse = await response.json()
        return jsonResponse
    }

    public async partial_update(path: string | string[], id: string, data: any): Promise<T> {
        const response = await fetch(this.url + "/" + this.formatterPathResponse(path, id) + "/", {
            method: 'PATCH',
            headers: this.headerRequest(),
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            this.errorTreatment(response)
        }
        const jsonResponse = await response.json()
        return jsonResponse
    }

    public async update(path: string | string[], id: string, data: T): Promise<T> {
        const response = await fetch(this.url + "/" + this.formatterPathResponse(path, id) + "/", {
            method: 'PUT',
            headers: this.headerRequest(),
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            this.errorTreatment(response)
        }
        const jsonResponse = await response.json()
        return jsonResponse
    }

    public async delete(path: string | string[], id: string): Promise<any> {
        const response = await fetch(this.url + "/" + this.formatterPathResponse(path, id) + "/", {
            method: 'DELETE',
            headers: this.headerRequest(),
        })
        if (!response.ok) {
            this.errorTreatment(response)
        }
        return { status: "deletado" }
    }

    private errorTreatment(response: any): any {
        console.error(response)
        if (response.status === 404)
            throw Error("[API] Não encontrado.")
        if (response.status === 401)
            throw Error("[API] Acesso Negado.")
        if (response.status === 500)
            throw Error("[API] Erro na API. Contate um administrador.")
        throw Error(response.statusText)
    }

    private formatterFilterRequest(filter: any): String {
        if (!filter || filter === undefined || filter === null || Object.keys(filter).length === 0) return ""
        let filterString = "?"
        for (let key in filter)
            if (filter[key] !== null && filter[key] !== undefined && filter[key] !== "") filterString += key + "=" + filter[key] + "&"

        return filterString;
    }
    private headerRequest(): any {
        return {
            'Content-Type': 'application/json',
            'Authorization': this.auth ? 'Bearer ' + this.auth.access : '',
        }
    }

    private formatterPathResponse(path: string | string[], id?: string): string {

        if (path instanceof Array) {
            let pathAux = [...path]
            if (id) return pathAux.shift() + "/" + id + "/" + pathAux.join("/")
            return path.join("/")
        }
        if (typeof path === "string") {
            if (id) return path + "/" + id
            return path
        }
        return ""
    }
}

export function useApi(auth?: authType) {
    return new Api<any>(auth)
}