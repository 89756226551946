import React from 'react';

// import { ResponsiveChoropleth } from '@nivo/geo'
// import geoJson from "./brazil_geoJSON.json"

import * as am5 from "@amcharts/amcharts5";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/brazilLow";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ApiClass } from '../../classComponents';
import { useFilterContext } from '../../contexts/contexts/FilterContext';
import { useUser } from '../../contexts/contexts/UserContext';
import ErrorLoading from '../errorLoading';
import Loading from '../loading';




export default function Chart3() {
    const chartRef = React.useRef<any>(null)
    const [getMachines] = React.useState<maquinasType[]>();
    const [user] = useUser();
    const api = new ApiClass<any>(user.credential)

    const [filter] = useFilterContext()
    const { data, isLoading, error } = useQuery(`getMaquinas-${JSON.stringify(filter)}`, () => api.get("maquinas", { ...filter }))

    function create_circles(root: any, color: am5.Color): am5.Circle[] {
        const circle1 = am5.Circle.new(root, {
            radius: 4,
            tooltipY: 0,
            fill: color,
            strokeOpacity: 0
        })
        circle1.animate({
            key: "scale",
            from: 1,
            to: 5,
            duration: 600,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity
        });
        circle1.animate({
            key: "opacity",
            from: 1,
            to: 0.1,
            duration: 600,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity
        });

        return [circle1,
            am5.Circle.new(root, {
                radius: 4,
                tooltipY: 0,
                fill: color,
                strokeOpacity: 0,
                tooltipText: "{title}"
            })]

    }


    React.useLayoutEffect(() => {

        var root = am5.Root.new("chartdiv");

        const color_red = am5.color("#d63939");
        const color_green = am5.color("#50bf8d");

        root.setThemes([am5themes_Animated.new(root)]);
        var chart = root.container.children.push(
            am5map.MapChart.new(root, {
                panX: "none",
                panY: "none",
                projection: am5map.geoMercator(),
                layout: root.horizontalLayout,
                zoomLevel: 8,
                x: 600,
                y: -190,
                maxZoomLevel: 8,
                minZoomLevel: 8,
            })
        );

        var backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));

        backgroundSeries.mapPolygons.template.setAll({
            fill: am5.color("#50bf8d"),
            fillOpacity: 0,
            strokeOpacity: 0,
        });

        backgroundSeries.data.push({
            geometry: am5map.getGeoRectangle(90, 180, -90, -180)
        });


        var polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                fill: am5.color("#013270"),
            })
        );

        var lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
        lineSeries.mapLines.template.setAll({
            stroke: root.interfaceColors.get("alternativeBackground"),
            strokeOpacity: 0.3
        });

        var pointSeriesGreen = chart.series.push(am5map.MapPointSeries.new(root, {}));
        var colorset = am5.ColorSet.new(root, {});

        function addBullet(pointSeries: any, color: am5.Color) {

            pointSeries.bullets.push(function () {
                let container = am5.Container.new(root, {
                    tooltipText: "{title}",
                    cursorOverStyle: "pointer",
                });

                container.events.on("click", (e: any) => {
                    if (e.target.dataItem) {
                        const maquina: maquinasType = e.target.dataItem.dataContext.maquina as maquinasType;
                        toast.info(maquina.maquinaconfiguration.ativo ? "Máquina Ativa" : "Máquina Inativa")
                    }
                });
                container.events.on("dragged", (e:any) => {
                    if (e.target) console.log("Dragged")
                })
                var circles = create_circles(root, color);

                for (var i = 0; i < circles.length; i++) {
                    container.children.push(circles[i]);
                }

                return am5.Bullet.new(root, {
                    sprite: container
                });
            });
        }

        addBullet(pointSeriesGreen, color_green);

        var pointSeriesRed = chart.series.push(am5map.MapPointSeries.new(root, {}));

        addBullet(pointSeriesRed, color_red);

        if (data)
            for (var i = 0; i < data?.length; i++) {
                const pointSeries = data[i].maquinaconfiguration.ativo ? pointSeriesGreen : pointSeriesRed;
                addMachine(pointSeries, data[i]);

            }
        function addMachine(pointSeries: any, maquina: maquinasType) {
            pointSeries.data.push({
                // url: url,
                maquina: maquina,
                geometry: { type: "Point", coordinates: [maquina.longitude, maquina.latitude] },
                title: maquina.nome,
            });
        }

        chart.appear(1000, 100);
        chartRef.current = chart;

        return () => {
            chart.dispose()
            root.dispose()
        }
    }, [chartRef, getMachines, data])

    // if (isLoading) return <Loading />
    // if (error) return <ErrorLoading error="Erro ao carregar o Chart6" objectError={error} />

    return (
        <>
            {
                isLoading ? <div className='position-absolute bg-dark' style={{ width: "100%", height: "100%", zIndex: 10, opacity: "50%" }}><Loading /></div> : null
            }
            {
                error ? <div className='position-absolute bg-dark' style={{ width: "100%", height: "100%", zIndex: 10, opacity: "50%" }}><ErrorLoading error="Erro ao carregar o Chart6" objectError={error} /></div> : null
            }
            <div id="chartdiv" style={{ width: '100%', height: '100%' }}></div>
        </>
    )
}

