import { Dispatch, createContext, useContext, useReducer } from "react";
import ModalReducer from "../reducers/ModalReducer";

export type ModalPropsType = {
    selected?: any;
    data?: any;
    show: boolean;
    title?: string;
    form?: (props:any) => JSX.Element;
    toolbar?: React.ReactNode;
    type: 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';
}


type ModalContextWrapperType = {
    children: React.ReactNode,
    defaultValue?: ModalPropsType
}

export const DefaultValue = [{ show: false, type: 'POST' }, () => { }] as ContextType<ModalPropsType>
const ModalContext = createContext<ContextType<ModalPropsType>>(DefaultValue)

const ModalContextWrapper = ({ children, defaultValue }: ModalContextWrapperType) => {
    const [valueModal, dispatch] = useReducer(ModalReducer, defaultValue ? defaultValue : DefaultValue)
    return (
        <ModalContext.Provider value={[valueModal, dispatch]}>
            {children}
        </ModalContext.Provider>
    )
}

const useModalContext = () => {
    return useContext(ModalContext)
}

export default ModalContextWrapper
export { ModalContext, useModalContext }