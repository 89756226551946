import React from 'react'

type Props = {
    overlay?: boolean;
}

export default function Loading({ overlay = false }: Props) {
    return (
        <>
            {
                overlay ? <div className='container d-flex justify-content-center ' style={{ height: "80%", width: "74%", position: 'absolute', backgroundColor:"transparent" }}>
                    <div className="spinner-grow text-success my-auto" style={{opacity:"100%"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> :
                    <div className='container d-flex justify-content-center ' style={{ height: "100%", width: "100%" }}>
                        <div className="spinner-grow text-success my-auto" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            }


        </>

    )
}
