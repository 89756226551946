/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import moment from 'moment';
import { FC } from 'react'

type Props = {
    machine: maquinasType;
}

const MachineInfoCell: FC<Props> = ({ machine }) => {

    return (
        <div className='d-flex align-items-center'>
            {/* begin:: Avatar */}
            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                <a href='#'>

                    <div
                        className={clsx(
                            'symbol-label fs-3',
                            `bg-light-${machine.identificador[0]}`,
                            `text-${machine.identificador[0]}`
                        )}
                    >
                        {machine.nome ? machine.nome[0] : "?"}
                    </div>
                </a>
            </div>
            <div className='d-flex flex-column'>
                <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                    {machine.nome || "Nenhum Nome"}
                </a>
                <span>{"identificador: " + machine.identificador || ""}</span>
            </div>
        </div>
    )
}

export { MachineInfoCell }
