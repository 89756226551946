import { Autocomplete, TextField } from "@mui/material"
import { useQuery } from "react-query"
import { useApi } from "../../classComponents"
import { useUser } from "../../contexts/contexts/UserContext"
import Loading from "../loading"
import { useFilterContext } from "../../contexts/contexts/FilterContext"

export default function RegionalFilterField() {
    const [user] = useUser()
    const api = useApi(user.credential)
    const [, filterDispatch] = useFilterContext()
    const regionals_query = useQuery(`requestFilterRegionals`, () => api.get("regionais"))
    return (
        <>
            {
                regionals_query.isLoading ? <Loading /> : <Autocomplete
                    disablePortal
                    options={regionals_query.data.map((x: any) => ({ label: x.nome, value: x.id })) as [{ label: string, value: string }] || []}
                    sx={{ width: 200 }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, value) => filterDispatch({ type: "SET_REGIONAL", payload: value?.value })}
                    renderInput={(params) => <TextField {...params} size="small" label="Regional" />}
                />
            }
        </>
    )
}