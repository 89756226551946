import { DashboardPage, MachinePage, SamplePage, UserPage } from "../../screens/SeedClassifierPages";

 const seedClassifierRoutes = [
    {
        path: "",
        element: <DashboardPage />,
    },
    {
        path: "dashboard",
        element: <DashboardPage />,
    },
    {
        path: "machines",
        element: <MachinePage />,
    },
    {
        path: "samples",
        element: <SamplePage />,
    },
    {
        path: "users",
        element: <UserPage />,
    }
]

export default seedClassifierRoutes