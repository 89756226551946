import { PropsWithChildren, useEffect } from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { Column } from 'react-table'
import { KTIcon } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ApiClass } from '../../../classComponents'
import { ListTable } from '../../../components'
import ErrorLoading from '../../../components/errorLoading'
import { EditUserForm } from '../../../components/forms/EditUserForm'
import { TextCell } from '../../../components/list_table/users-list/table/columns/usersCells/TextCell'
import { UserCustomHeader } from '../../../components/list_table/users-list/table/columns/usersCells/UserCustomHeader'
import { UserInfoCell } from '../../../components/list_table/users-list/table/columns/usersCells/UserInfoCell'
import Loading from '../../../components/loading'
import { BaseModal } from '../../../components/modals/BaseModal'
import { ListComponentWrapper } from '../../../contexts'
import ModalContextWrapper, { useModalContext } from '../../../contexts/contexts/ModalContext'
import { PaginationContextWrapper, usePagination } from '../../../contexts/contexts/PaginationContext'
import { useUser } from '../../../contexts/contexts/UserContext'
import { UserActionCell } from '../../../components/list_table/users-list/table/columns/usersCells/UserActionsCell'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/seed_classifier/dashboard/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const usersColumns: ReadonlyArray<Column<userType>> = [
  // {
  //   Header: (props: PropsWithChildren<any>) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({ ...props }) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Cargo' className='min-w-125px' />,
    id: 'text',
    Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].groups.length > 0 ? props.data[props.row.index].groups[0].name : "Nenhum"} />,
  },

  {
    Header: (props: PropsWithChildren<any>) => (
      <UserCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
      id: 'actions',
      Cell: ({ ...props }) => <UserActionCell item={props.data[props.row.index] as {}} />,
  },
]

const queryClient = new QueryClient()

export default function UserPageWrapper() {

  return (
    <QueryClientProvider client={queryClient}>
      <ModalContextWrapper defaultValue={{ form: EditUserForm, show: false, title: "Usuário", data: undefined, type: 'PATCH' }}>
        <PaginationContextWrapper>
          <UserPage />
        </PaginationContextWrapper>
      </ModalContextWrapper>
    </QueryClientProvider>

  )
}

export function UserPage() {

  const [user] = useUser()
  const [pagination] = usePagination()
  const api = new ApiClass<userType>(user.credential)
  const [getModalContext, setModalContext] = useModalContext()

  const { data, isLoading, error, refetch } = useQuery(`users-list-${JSON.stringify(pagination)}`, async (): Promise<userType[] | userType | IPagination<userType>> => api.get("users", { ...pagination }))

  useEffect(() => {
    setModalContext({
      type: "SET_TOOLBAR", payload: (
        <>
          <button type='button' className='btn btn-primary' onClick={() => setModalContext({ type: "SET", payload: { show: true, data: undefined, title: "Adicionar", type: 'POST' } })} >
            <KTIcon iconName='plus' className='fs-2' />
            Adicionar
          </button>
        </>
      )
    })
    refetch()
  }, [pagination])

  // if (isLoading && data == undefined) return <Loading />
  if (error) return <ErrorLoading error={"Não foi possível buscar os usuários"} objectError={error} />


  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Usuários</PageTitle>
      {
        isLoading && <Loading overlay={true} />
      }
      <ListComponentWrapper content={data !== undefined ? ({ ...((data as IPagination<userType>).results !== undefined ? (data as IPagination<userType>) : { results: (data as userType[]) }), columns: usersColumns }) : ({ ...({ results: ([]) }), columns: usersColumns })}>
        <ListTable />
      </ListComponentWrapper>
      <BaseModal title={getModalContext?.title} show={getModalContext.show} handleClose={() => setModalContext({ type: "SHOW", payload: false })}>
        {getModalContext?.form ? <getModalContext.form /> : null}
      </BaseModal>
    </>
  )
}


