import React from 'react'

type Props = {
    name: string;
    title?: string;
    defaultValue?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    editable?: boolean;
}


export default function CheckField(props: Props) {
    const name_array = props.name.split(".")
    const [getValue, setValue] = React.useState(name_array.length === 2 ? (props.defaultValue[name_array[0]][name_array[1]] ? props.defaultValue[name_array[0]][name_array[1]] : "") : (props.defaultValue[props.name] ? props.defaultValue[props.name] : false))
    return (
        <div className="form-check form-switch form-check-custom form-check-solid ms-5 ">
            <input className="form-check-input" type="checkbox" name={props.name} checked={getValue} value="" disabled={!props.editable} id="flexSwitchDefault" onChange={props.onChange ? props.onChange : (e) => { setValue(e.target.checked) }} />
            <label className="form-check-label " >
                {props.title ? props.title : props.name}
            </label>
        </div>
    )
}
