/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { useUser } from '../../contexts/contexts/UserContext'
import { ApiClass, UserClass } from '../../classComponents'
import { useQuery } from 'react-query'
import Loading from '../loading'
import ErrorLoading from '../errorLoading'
import { useFilterContext } from '../../contexts/contexts/FilterContext'


type Props = {
  className?: string
  items?: number
}

const ListMachines: React.FC<Props> = ({ className, items = 6 }) => {
  const [user] = useUser();
  const userClass = new UserClass(user.profile)
  const [filter] = useFilterContext()
  const api = new ApiClass<maquinasType>(user.credential)
  const { data, isLoading, error } = useQuery(`maquinas-${JSON.stringify(filter)}`, async () => api.get("maquinas", { ...filter }))

  if (isLoading) return <Loading />
  if (error) return <ErrorLoading error="Erro ao carregar a lista de regionais" objectError={error} />

  return (
    <>
      {
        (data as maquinasType[]).sort((a, b) => b.classificacoes - a.classificacoes).map((item: maquinasType, index: number) => {
          return <div key={index} className='d-flex align-items-sm-center mb-7'>
            {/* begin::Symbol */}
            <div className='symbol symbol-50px me-5 ms-3'>
              <span className='symbol-label'>
                <img
                  src={toAbsoluteUrl('/media/logos/default-small.png')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className='d-flex align-items-center flex-row-fluid flex-wrap me-5'>
              <div className='flex-grow-1 me-2'>
                <a href={userClass.hasPermission("gestor") || userClass.isStaff() ? "/seed_classifier/machines/?search=" + item.nome : "#"} className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  {item.nome && item.nome.length > 20 ? item.nome.slice(0, 20) + "..." : item.nome}
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>{item.regional?.nome}</span>
              </div>
              <span className='badge badge-light fw-bold my-2'>{item.classificacoes}</span>
            </div>
            {/* end::Section */}
          </div>
        })
      }

    </>
  )
}

export { ListMachines }

