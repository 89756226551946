const requestUrl = (process.env.REACT_APP_REQUEST_URL || 'http://localhost:8080') + "/api";
const staticRequestUrl = (process.env.REACT_APP_STATIC_REQUEST_URL || 'http://localhost:8080') + "/static";
const requestUrlClean = (process.env.REACT_APP_STATIC_REQUEST_URL || 'http://localhost:8080') ;

const api = async ({ method, path, body, auth }: methodsType) => {
	const response = await fetch(requestUrl + path, {
		method,
		body: body ? JSON.stringify(body) : null,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': auth ? 'Bearer ' + auth.access : '',
		}

	})
	if (!response.ok) {
		throw Error(response.statusText)
	}
	const jsonResponse = await response.json()
	return jsonResponse
}


const api_image = async ({ method, path, body, auth }: methodsType) => {
	const response = await fetch(requestUrl + path, {
		method,
		body: body ? body : null,
		headers: {
			// 'Content-Type': 'multipart/form-data',
			Authorization: auth ? 'Bearer ' + auth.access : ''
		}
	})

	if (!response.ok) {
		console.error(await response.json())
		throw Error(response.statusText)
	}
	const jsonResponse = await response.json()
	return jsonResponse
}


export default api;
export {staticRequestUrl,requestUrl,api_image,requestUrlClean};


