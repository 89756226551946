import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ThemeModeComponent } from "../../_metronic/assets/ts/layout";
import { useThemeMode } from "../../_metronic/partials";
import { is_darkmode } from "../../utils/darkmode";

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'
export const themeChartNivoContext = createContext({} as any)
export default function ThemeObserverWrapper({ children }: any) {
    const { mode } = useThemeMode()
    const [themeChartNivo, setThemeChartNivo] = useState({})

    const calculatedMode = useMemo(() =>
        mode === 'system' ? systemMode : mode
        , [mode])


    useEffect(() => {
        setThemeChartNivo({
            labels: {
                text: {
                    fontSize: 14,
                    fill: calculatedMode == 'dark' ? "#fff" : "#000",
                    outlineWidth: 0,
                    outlineColor: "transparent",
                },
            },
            text: {
                fill: calculatedMode == 'dark' ? "#fff" : "#000",
            },
            axis: {
                ticks: {
                    text: {
                        fill: calculatedMode == 'dark' ? "#fff" : "#000",
                        fontSize: ".6em",
                    }
                },
                legend: {
                    text: {
                        fill: calculatedMode == 'dark' ? "#fff" : "#000",
                    }
                }
            },
            legends: {
                title: {
                    text: {
                        fill: "red",
                    }
                }

            },
            annotations: {
                text: {
                    fill: calculatedMode == 'dark' ? "#fff" : "#000",
                }
            }
        })
    }, [mode])

    const Theme = createTheme({
        palette: {
            mode: calculatedMode,
        },
    });

    return (
        <ThemeProvider theme={Theme}>
            <themeChartNivoContext.Provider value={themeChartNivo}>
                {/* <CssBaseline /> */}
                {children}
            </themeChartNivoContext.Provider>
        </ThemeProvider>
    )
}


