import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../contexts/contexts/UserContext';
import { getEnterprises } from '../../services/enterprise';

export default function Choice() {
    const [value, setValue] = React.useState("null");
    const [user, dispatch] = useUser()
    const [choices, setChoices] = React.useState<{ name: string, value: string }[]>([])
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };


    React.useEffect(() => {
        if (user.credential)
            getEnterprises(user.credential).then(res => {
                setChoices(res.map(enterprise => { return { name: enterprise.nome, value: String(enterprise.id) } }))
            }).catch(err => {
                toast.error(err.message)
                dispatch({ type: "RESET" })
                navigate("/login/")
            })
        else {
            toast.error("Login não detectado")
            dispatch({ type: "RESET" })
            navigate("/login/")

        }
    }, [])

    return (
        <div className='w-75 mx-auto'>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Empresas</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    value={value}
                    label="empresas"
                    name="enterprise"
                    onChange={handleChange}
                    size="small" sx={{ background: "white" }}
                >
                    <MenuItem value={"null"}>Nenhuma</MenuItem>
                    {choices.map((choice, index) => <MenuItem key={index} value={choice.value}>{choice.name}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
    )
}
