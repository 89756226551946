/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import { UserClass } from '../../../../../../classComponents'


type Props = {
  user: userType
}

const UserInfoCell: FC<Props> = ({user}) =>{ 
  const userFunctions = new UserClass(user)
  
  return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user?.foto ? (
          <div className='symbol-label'>
            <img src={userFunctions.getPhoto(false)} alt={user.first_name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.first_name[0]}`,
              `text-${user.first_name[0]}`
            )}
          >
            {user.first_name[0]+(user.last_name[0] ? user.last_name[0] : "") || "?"}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {userFunctions.getFullName() || "Nenhum"}
      </a>
      <span>{user.email || ""}</span>
    </div>
  </div>
)}

export {UserInfoCell}
