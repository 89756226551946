import { Autocomplete, TextField } from "@mui/material"
import { useQuery } from "react-query"
import { useApi } from "../../classComponents"
import { useUser } from "../../contexts/contexts/UserContext"
import Loading from "../loading"
import { useFilterContext } from "../../contexts/contexts/FilterContext"
import { CSSProperties, useState } from "react"

type GrainFilterFieldLocalProps = {
    className?: string;
    state: [filterLocal:any,setFilterLocal:React.SetStateAction<any>]
    style?:CSSProperties
}

export function GrainFilterFieldLocal({ className,state,style }: GrainFilterFieldLocalProps) {
    const [user] = useUser()
    const api = useApi(user.credential)
    const types_query = useQuery(`requestFilterTypes`, () => api.get("graos"))
    const [filterLocal, setFilterLocal] = state

    return (
        <>
            {
                types_query.isLoading ? <Loading /> : <Autocomplete
                    className={className}
                    style={style}
                    disablePortal
                    options={types_query.data.map((x: any) => ({ label: x.name, value: x.id })) as [{ label: string, value: string }] || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, value) => setFilterLocal({ produto: value?.value })}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} size="small" label="Tipo" />}
                />
            }
        </>
    )
}

export default function GrainFilterField() {
    const [user] = useUser()
    const api = useApi(user.credential)
    const [, filterDispatch] = useFilterContext()
    const types_query = useQuery(`requestFilterTypes`, () => api.get("graos"))
    return (
        <>
            {
                types_query.isLoading ? <Loading /> : <Autocomplete
                    disablePortal
                    options={types_query.data.map((x: any) => ({ label: x.name, value: x.id })) as [{ label: string, value: string }] || []}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, value) => filterDispatch({ type: "SET_TYPE", payload: value?.value })}
                    sx={{ width: 200 }}
                    renderInput={(params) => <TextField {...params} size="small" label="Tipo" />}
                />
            }
        </>
    )
}