import {FC} from 'react'

type Props = {
  text?: string
}

const TextCell: FC<Props> = ({text}) => (
  <div className='badge badge-light fw-bolder'>{text}</div>
)

export {TextCell}
