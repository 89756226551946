
export default function UserReducer(user: IUserContext, action: any) {
    switch (action.type) {
        case "SET_CREDENTIAL":
            window.localStorage.setItem("a6a24033-80f8-493c-9252-7361f6f85b41", JSON.stringify(action.payload))
            return { ...user, credential: action.payload }
        case "SET_PROFILE":
            window.localStorage.setItem("f1631e21-2772-4529-b3a1-f46594a57f0d", JSON.stringify(action.payload))
            return { ...user, profile: action.payload }
        case "SET_ENTERPRISE":
            window.localStorage.setItem("f3e6eb95-b809-48a9-9219-758a3215b071", JSON.stringify(action.payload))
            return { ...user, enterprise: action.payload }
        case "SET_SERVICES":
            window.localStorage.setItem("3dc922f6-3e6b-4747-a3b9-bdbcef048be8", JSON.stringify(action.payload))
            return { ...user, services: action.payload }
        case "RESET":
            window.localStorage.removeItem("a6a24033-80f8-493c-9252-7361f6f85b41")
            window.localStorage.removeItem("f1631e21-2772-4529-b3a1-f46594a57f0d")
            window.localStorage.removeItem("f3e6eb95-b809-48a9-9219-758a3215b071")
            window.localStorage.removeItem("3dc922f6-3e6b-4747-a3b9-bdbcef048be8")
            return {}
        default:
            return user
    }
}