import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RequestUrl } from '../../classComponents/api/index.class'
import { useUser } from '../../contexts/contexts/UserContext'
import { EnterpriseChoiceForm, EnterpriseForm, LoginForm } from '../../forms'
import { login } from '../../services/auth'
import { createEnterprise as createEnterpriseutils, getEnterprise } from '../../services/enterprise'
import { changeEnterprise, getProfile } from '../../services/profile'

const checkEnterpriseForm = (getAuth: authType, enterprise: string) => {
    if (enterprise === "null") {
        toast.error("Selecione uma empresa!")
        return false
    } else {
        changeEnterprise(getAuth, parseInt(enterprise)).then(res => {
            toast.success("Empresa Selecionada ")
        }).catch(err => {
            toast.error(err.message)
        })
        return true
    }
}


export default function LoginPage() {
    const [user, dispatch] = useUser();
    const [createEnterprise, setCreateEnterprise] = React.useState(false)
    const navigate = useNavigate();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const target = e.target as HTMLFormElement
        if (createEnterprise && user.credential) {
            const { nome, cnpj, cidade, estado } = target
            createEnterpriseutils(user.credential, { cnpj: cnpj.value, nome: nome.value, cidade: cidade.value, estado: estado.value } as enterpriseCreateType).then(res => {
                toast.success("Empresa Criada com Sucesso!")
                target.reset()
                setCreateEnterprise(false)
            }).catch(err => {
                toast.error(err.message)
            })
            return
        }

        if (user.credential && user.profile && user.profile?.is_staff) {
            const { enterprise } = target
            if (checkEnterpriseForm(user.credential, enterprise.value))
                getEnterprise(user.credential).then(res => {
                    if (res?.id) {
                        dispatch({ type: "SET_ENTERPRISE", payload: res })
                        navigate("/homepage/")
                    }
                    else {
                        toast.error("Usuário sem empresa definida!")
                        dispatch({ type: "SET_ENTERPRISE", payload: null })
                    }
                })

            return
        }

        const { email, password } = target
        login(email.value, password.value).then(res => {
            dispatch({ type: "SET_CREDENTIAL", payload: res })
            getProfile(res).then(res => {
                dispatch({ type: "SET_PROFILE", payload: res })
            })
            getEnterprise(res).then(res => {
                if (res)
                    dispatch({ type: "SET_ENTERPRISE", payload: res })
                else {
                    dispatch({ type: "SET_ENTERPRISE", payload: null })
                    toast.error("Usuário sem empresa definida!")
                }
            }).catch(err => {
                toast.error("Usuário sem empresa definida!")
                dispatch({ type: "SET_ENTERPRISE", payload: null })
            })
            toast.success(`Login Efetuado com Sucesso!`)
        }).catch(err => {
            toast.error(err.message)
        })

    }

    useEffect(() => {
        if (user.profile && user.credential && !user.profile?.is_staff)  navigate("/homepage/")
    } , [])

    return (
        <div className="container-fluid" style={{ backgroundColor: "var(--secondary-color)", width: "100vw", height: "100vh" }}>
            <div className="row h-100">
                <div className="col-10 col-md-3 mx-auto h-auto my-auto rounded" style={{ backgroundColor: "var(--primary-color)" }}>
                    <form onSubmit={onSubmit} method="post">
                        <div className="d-flex justify-content-center text-center mt-1 pt-2 mb-1 ">
                            <div className="bg-white rounded-circle p-2 mb-5 " style={{ width: "min-content", marginTop: "-15%", border: " solid #042234 10px" }}>
                                <img src={RequestUrl + '/static/img/logo.png'} alt="" />
                            </div>
                        </div>

                        {
                            createEnterprise ? <>
                                <EnterpriseForm />
                                <div className='row w-75 mx-auto'>
                                    <a className='mt-2 text-danger' href='#' onClick={() => setCreateEnterprise(false)}>Cancelar</a>
                                    <input className="btn btn-primary mt-3 mb-5" type="submit" value="Criar Empresa" style={{ backgroundColor: "var(--secondary-color)", color: "white" }} />
                                </div></> :
                                user.profile && user.credential ? user.profile?.is_staff ? <>
                                    <EnterpriseChoiceForm />

                                    <div className='row w-75 mx-auto'>
                                        <a className='mt-2' href='#' onClick={() => setCreateEnterprise(true)}>Criar Nova Empresa</a>
                                        <input className="btn btn-primary mt-3 mb-5" type="submit" value="Entrar na empresa" style={{ backgroundColor: "var(--secondary-color)", color: "white" }} />
                                    </div>
                                </> : <>{navigate("/homepage/")}</> : <>
                                    <LoginForm />
                                    <div className='row w-75 mx-auto'>
                                        <input className="btn btn-primary mt-3 mb-5" type="submit" value="Logar" style={{ backgroundColor: "var(--secondary-color)", color: "white" }} />
                                    </div></>
                        }

                    </form>
                </div>
            </div>
        </div>
    )
}
